
.bubbleWrapper {
    display: flex;
    margin-bottom: 15px;
    margin-right: 15px;
    margin-left: 15px;
    
    .bubble {
        font-size: 16px;
        padding: 10px 20px;
        width: fit-content;
        border-radius: 24px;
        max-width: 80%;
    }

    &.sent {
        justify-content: right;

        .bubble {
            background: #1FA859;
            color: #64FFDA;
        }
    }

    &.received {
        justify-content: left;

        .bubble {
            background: #64FFDA;
            color: #1FA859;
        }
    }
}

.typing {
    margin: 10px 35px 50px;
}